module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Brian Emilius' Personal Site","short_name":"BE Site","start_url":"/","background_color":"#242442","theme_color":"#242442","display":"standalone","icon":"./src/images/icon.png","crossOrigin":"use-credentials"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":786},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
